export const account = [
    {
        path: "/mijn-roadpursuit/login",
        name: "Login",
        component: () => import("@/views/account/Login.vue"),
    },
    {
        path: "/mijn-roadpursuit/registratie",
        name: "Registratie",
        component: () => import("@/views/account/Registratie.vue"),
    },
    {
        path: "/mijn-roadpursuit/activeren/:activationString",
        name: "Registratie Welkom",
        component: () => import("@/views/account/RegistratieWelkom.vue"),
    },

    {
        path: "/mijn-roadpursuit/wachtwoord-vergeten",
        name: "Wachtwoord Vergeten",
        component: () => import("@/views/account/WachtwoordVergeten.vue"),
    },
    {
        path: "/mijn-roadpursuit/wachtwoord-wijzigen",
        name: "Wachtwoord Wijzigen",
        component: () => import("@/views/account/WachtwoordWijzigenDashboard.vue"),
    },
];