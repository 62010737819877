import { RolesEnum } from '@/enums/RolesEnum';

class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }
    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.token;
    }
    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.token = token;
        localStorage.setItem("user", JSON.stringify(user));
    }
    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }
    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }
    removeUser() {
        localStorage.removeItem("user");
    }
    getRPKey(role) {
        //RoadpursuitAuthenticated
        return role == RolesEnum.Administrator ? '$2y$19$NKUhMtAs3rZMIOZOi7u4yORnfAdFsMg/Uq8D8f6XEIDPPxs9F0JEW' : ''; 
    }
    isAdmin()
    {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.rpKey == this.getRPKey(RolesEnum.Administrator);
    }

    
}
export default new TokenService();