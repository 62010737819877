<template>
    <v-footer dark padless v-if="showFooter">
        <v-card class="flex pt-12 text-center" flat tile>
            <v-card-text class="main-footer">
                <div class="footer-container">
                    <div>© Roadpursuit {{ getCurrentYear() }}</div>
                        <div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        :style="style"
                                        target="_blank"
                                        href="https://www.roadpursuit.com/data/algemene-voorwaarden-roadpursuit.pdf"
                                        @click.stop
                                        v-on="on">
                                        Algemene Voorwaarden</a>
                                </template>
                                    Opent nieuw tabblad
                            </v-tooltip>
                            |
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        :style="style"
                                        target="_blank"
                                        href="https://roadpursuit.com/data/privacyverklaring-roadpursuit.pdf"
                                        @click.stop
                                        v-on="on">
                                        Privacyverklaring</a>
                                </template>
                                    Opent nieuw tabblad
                            </v-tooltip>
                            |
                            <a 
                                :style="style"
                                href="https://www.roadpursuit.com/over-ons"
                                @click.stop>
                                Contact en nieuwsbrief
                            </a>
                        </div>
                    <div class="mb-2">Bijlmerdreef 424, 1102 AC Amsterdam | KvK 82865914</div>
                    <SocialMediaLinks />
                </div>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex';
import FormatMixin from "@/mixins/format.mixin";
import SocialMediaLinks from "@/components/common/SocialMediaLinks";

export default {
    name: 'MainFooter',
    mixins: [FormatMixin],
    components: {
        SocialMediaLinks
    },
    computed: {
        ...mapState({
            showFooter: state => state.showFooter
        }),
    },
    data: () => ({
        style: 'color: rgb(110 153 197)'
    }),
}
</script>
<style>
.main-footer
{
    font-family: 'Dosis', sans-serif !important;
    border-top: 1px solid #33518b47;
    text-align: center;
}

.main-footer .footer-container
{
    color: rgb(122 125 129);
    font-size: small;
} 

.footer-container .logo-fb,
.footer-container .logo-insta
{
    margin: 13px 5px;
}
</style>