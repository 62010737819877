<template>
    <div>
        <img class="logo-fb" :src="facebookLogoSrc" alt="Facebook" @click="goToFacebook">
        <img class="logo-insta" :src="instagramLogoSrc" alt="Instagram" @click="goToInstagram">
    </div>
</template>

<script>
export default {
    name: 'SocialMediaLinks',

    data: () => ({
        facebookLogoSrc:  require('@/assets/icons/facebook-logo.svg'),
        instagramLogoSrc:  require('@/assets/icons/Instagram_Icon.svg'),
        facebookLink: 'https://www.facebook.com/roadpursuit',
        instagramLink: 'https://www.instagram.com/roadpursuit/'
    }),
    methods: {
        goToFacebook()
        {
            window.open(this.facebookLink, '_blank');
        },
        goToInstagram()
        {
            window.open(this.instagramLink, '_blank');
        }
        
    }
}
</script>
<style scoped>
.logo-fb,
.logo-insta
{
    width: 35px;
    height: 35px;
    cursor: pointer;
}
</style>
