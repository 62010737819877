
import TokenService from "./token.service";
import { RolesEnum } from '@/enums/RolesEnum';

export const userService = {
    login,
    logout,
    handleResponse,
};

function login(email, password) {

    return new Promise((resolve, reject) => {

        const request = {
            "email" : email,
            "password" : password
        }
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
    
        return fetch(`${process.env.VUE_APP_BASE_URL}/authentication/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                let userCache = {
                    token : user.token,
                    userId : user.userId,
                    refreshToken : user.refreshToken
                }
                
                let role = user.role;

                if (role == RolesEnum.Administrator)
                {
                    userCache["rpKey"] = TokenService.getRPKey(role);
                }

                TokenService.setUser(userCache);
            }
            resolve(user);
            return user;
        })
        .catch(error => reject(error))
    })
    
   
}

function logout() {
    // remove user from local storage to log user out
    TokenService.removeUser();
}

function handleResponse(response) {
    return response.text()
    .then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            return Promise.reject(text);
        }

        let data = text && JSON.parse(text);
        return data;
    });
}