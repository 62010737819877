export default {
    methods: 
    {
        // Home
        goToHome()
        {
            this.goto(`/`);
        },

        // Account
        goToLoginOnly()
        {
            this.goto(`/mijn-roadpursuit/login`);
        },
        goToLogin(redirectPath)
        {
            this.gotoAndRedirect(`Login`, redirectPath);
        },
        goToChangePassword()
        {
            this.goto(`/mijn-roadpursuit/wachtwoord-wijzigen`);
        },

        // Dashboard
        goToMyDashboard()
        {
            this.goto(`/dashboard`);
        },
        goToAccount()
        {
            this.goto(`/dashboard/account`);
        },
        gotoDeleteAccount()
        {
            this.goto(`/dashboard/account/verwijderen`);
        },
        goToUpComingTrip(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/dashboard/aankomend/${title}`);
        },
        goToUpComingTripWijzigen(title)
        {
            this.goto(`/dashboard/aankomend/${title}/wijzigen`);
        },
        goToAllPastTrips()
        {
            this.goto(`/dashboard/afgelopen`);
        },
        goToPastTrip(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/dashboard/afgelopen/${title}`);
        },

        // Admin 
        goToNewRoadtrip()
        {
            this.goto(`/dashboard/roadtrips/toevoegen`);
        },
        goToRoadtrip(id)
        {
            this.goto(`/dashboard/roadtrips/${id}`);
        },
        goToRoadtripWijzigen(id)
        {
            this.goto(`/dashboard/roadtrips/wijzigen/${id}`);
        },
        goToNewInvoice()
        {
            this.goto(`/dashboard/roadtrips/kies-trip/nieuwe-factuur`);
        },
        goToRoadtripNewInvoice(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/dashboard/roadtrips/${title}/nieuwe-factuur`);
        },
        goToFinances()
        {
            this.goto(`/dashboard/financien`);
        },
        goToAccounts()
        {
            this.goto(`/dashboard/accounts`);
        },
        goToAccountId(accountId)
        {
            this.goto(`/dashboard/accounts/${accountId}`);
        },
        goToCar(carId)
        {
            this.goto(`/dashboard/voertuigen/${carId}`);
        },
        goToCars()
        {
            this.goto(`/dashboard/voertuigen`);
        },
        goToSuppliers()
        {
            this.goto(`/dashboard/leverancieren`);
        },
        goToNewSupplier()
        {
            this.goto(`/dashboard/leverancieren/toevoegen`);
        },
        goToSupplierId(accountId)
        {
            this.goto(`/dashboard/leverancier/${accountId}`);
        },

        // Galerij
        goToGalleryAndReviews()
        {
            this.goto(`/galerij`);
        },
        goToGallery(title)
        {
            this.goto(`/galerij/${title.toLowerCase()}`);
        },
        goToGalleryTrip(make, trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/galerij/${make.toLowerCase()}/${title}`);
        },

        // Kalender
        goToMainCalendar()
        {
            this.goto(`/kalender`);
        },
        goToCalendar(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/kalender/${title}`);
        },
        goToRoadtripAanmelden(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/kalender/${title}/aanmelden`);
        },
        goToRoadtripAanmeldenGuest(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/kalender/${title}/aanmelden/gast`);
        },
        goToRoadtripAanmeldenNewAccount(trip, date)
        {
            const title = this.getTitleFormat(trip, date);
            this.goto(`/kalender/${title}/aanmelden/nieuw-account`);
        },

        // trip day 
        goToTripStatistics(roadtripId)
        {
            this.goto(`/tripday/statistics/${roadtripId}`);
        },
        goToCheckinPage(orderId)
        {
            this.goto(`/roadtrip/scanqr/${orderId}`);
        },

        goToAboutUs()
        {
            this.goto('/over-ons');
        },

        goto(path)
        {
            this.$router.push({ path: path });
        },
        gotoAndRedirect(path, redirectPath)
        {
            this.$router.push({ name: path, query: { redirect: redirectPath } });
        },
        getTitleFormat(trip, date)
        {
            return `${trip.replace(/\s+/g, '-').toLowerCase()}-${date}`; //{trip-name}-{day-month-year}/
        }
    }
}