export const admin = [
    {
        path: "/dashboard/roadtrips/toevoegen",
        name: "RoadtripNieuw",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Roadtrip(Nieuw).vue"),
    },
    {
        path: "/dashboard/roadtrips/:id",
        name: "RoadtripBestaand",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Roadtrip(Bestaand).vue"),
    },
    {
        path: "/dashboard/roadtrips/wijzigen/:id",
        name: "RoadtripBestaandWijzigen",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Roadtrip(Bestaand)Wijzigen.vue"),
    },
    {
        // the trip is auto-selected in the dropdown if given
        path: "/dashboard/roadtrips/:trip/nieuwe-factuur", // /{trip-name}-{day-month-year}
        name: "Nieuwe Factuur - Roadtrip",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/NieuweFactuur.vue"),
    },
    {
        path: "/dashboard/roadtrips/kies-trip/nieuwe-factuur",
        name: "Nieuwe Factuur",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/NieuweFactuur.vue"),
    },
    {
        path: "/dashboard/financien",
        name: "Financiën",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Financiën.vue"),
    },
    {
        path: "/dashboard/accounts",
        name: "Accounts",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Accounts.vue"),
    },
    {
        path: "/dashboard/accounts/:accountId",
        name: "Accountgegevens",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Accountgegevens.vue"),
    },
    {
        path: "/dashboard/voertuigen",
        name: "Voertuigen",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Voertuigen.vue"),
    },

    {
        path: "/dashboard/leverancieren",
        name: "Leverancieren",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Leverancieren.vue"),
    },
    {
        path: "/dashboard/leverancieren/toevoegen",
        name: "LeverancierenNieuw",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Leverancier(Nieuw).vue"),
    },
    {
        path: "/dashboard/leverancier/:accountId",
        name: "Leverancier",
        requiresPrimaryUser: true,
        component: () => import("@/views/admin/Leverancier.vue"),
    },
];