import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from 'vuex';
import VueMeta from 'vue-meta'
import store from "./store";
import setupInterceptors from './services/setupInterceptors';
Vue.use(Vuex);
Vue.use(VueMeta);
setupInterceptors(store);

Vue.prototype.$API = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = Vue.prototype.$API;
Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false
    Vue.config.productionTip = false
} 
require('@/assets/styles/main.css')
require('@/assets/styles/darktheme.css')

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
