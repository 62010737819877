/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const expenseModule = {
    namespaced: true,
    state:
    {
    },
    getters:
    {
        
    },
    mutations:
    {
       
    },
    actions:
    {
        getFinancialStatisticsByTimeFrame({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Expense/admin/dashboard/GetFinancialStatisticsByTimeFrame`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
};

export default expenseModule;