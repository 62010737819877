/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const productModule = {
    namespaced: true,
    state:
    {
    },
    getters:
    {
        
    },
    mutations:
    {
       
    },
    actions:
    {
        getAdminProduct({}, roadtripId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Product/GetAdminProduct?roadtripId=${roadtripId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        GetProductsByMonthAndYear({}, payload)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Product/GetProductsByMonthAndYear?month=${payload.month}&year=${payload.year}&accountId=${payload.accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getPublicProductByNameWhenLoggedIn({}, request)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Product/GetPublicProductByNameWhenLoggedIn?uniqueName=${request.uniqueName}&accountId=${request.accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getPublicProductByName({}, roadtripName)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Product/getPublicProductByName?uniqueName=${roadtripName}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getNextRoadtripDate({})
        {
            return new Promise((resolve, reject) => {
                api.get(`/Product/GetNextRoadtripDate`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        adjustProductStatus({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Product/AdjustProductStatus`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
};

export default productModule;