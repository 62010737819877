import store from "@/store";
export const kalender = [
    {
        path: `/kalender`,
        name: `Roadtrips in ${store.state.selectedYear}`,
        isMenu: true,
        requiresRoleCheck: true,
        component: () =>
            import("@/views/kalender/Kalender.vue"),
    },
    {
        path: `/kalender/:trip`, // {trip-name}-{day-month-year}
        name: `Roadtrip (Store)`,
        requiresRoleCheck: true,
        component: () =>
            import("@/views/kalender/RoadtripStore.vue"),
    },
    {
        path: `/kalender/:trip/aanmelden`, // {trip-name}-{day-month-year}/aanmelden
        name: `Roadtrip (Aanmelden)`,
        component: () =>
            import("@/views/kalender/RoadtripAanmelden.vue"),
    },
    {
        path: `/kalender/:trip/aanmelden/gast`, // {trip-name}-{day-month-year}/aanmelden/gast
        name: `Roadtrip (Aanmelden - Gast)`,
        component: () =>
            import("@/views/kalender/RoadtripAanmeldenGast.vue"),
    },
    {
        path: `/kalender/:trip/aanmelden/nieuw-account`, // {trip-name}-{day-month-year}/aanmelden/nieuw-account
        name: `Roadtrip (Aanmelden - Nieuw Account)`,
        component: () =>
            import("@/views/kalender/RoadtripAanmeldenNieuwAccount.vue"),
    },
];