export const tripdaymanager = [
    {
        path: "/roadtrip/scanqr/:orderId",
        name: "Check In",
        isMenu: false,
        requiresRoleCheck: true,
        component: () =>
            import("@/views/tripdaymanager/CheckIn.vue"),
    },
    {
        path: "/tripday/statistics/:roadtripId",
        name: "Trip Day Statistics",
        isMenu: false,
        requiresPrimaryUser: true,
        component: () =>
            import("@/views/tripdaymanager/TripDayStatistics.vue"),
    },
];