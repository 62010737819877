/* eslint-disable no-unused-vars */
import { userService, role } from '../../services';
import router from '@/router';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user, role: '' }
    : { status: {}, user: null, role: '' };

const actions = {
    login({ dispatch, commit }, {email, password, redirectPath}) {
        commit('loginRequest', { email });
        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);

                    // TODO: implement is admin flag
                    const isAdmin = false;

                    commit('authorizedModule/setAdmin', isAdmin, { root: true });
                    router.push(redirectPath || '/dashboard')
                },
                error => {
                    commit('dialog/showDialog', error, { root: true });
                    commit('loginFailure', error);
                    commit('authorizedModule/setAdmin', false, { root: true });
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        commit('authorizedModule/setAdmin', false, { root: true });
        userService.logout();
        router.push('/mijn-roadpursuit/login');
        commit('logout');
    },
    refreshToken({ commit }, accessToken) {
        commit('refreshToken', accessToken);
    },
    setRole({ commit }, role) {
        commit('setRole', role);
    }
};

const mutations = {
    loginRequest(state, email) {
        state.status = { loggingIn: true };
        state.user = email;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    refreshToken(state, accessToken) {
        state.status.loggedIn = true;
        state.user = { ...state.user, accessToken: accessToken };
    },
    setRole(state, role) {
        state.role = role;
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};