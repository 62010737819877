/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const supplierModule = {
    namespaced: true,
    state:
    {
    },
    getters:
    {
        
    },
    mutations:
    {
       
    },
    actions:
    {
        addSupplier({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Supplier/admin/AddSupplier`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        updateSupplier({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Supplier/admin/UpdateBaseSupplierInformation`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getSupplierFromAccount({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Supplier/admin/GetBaseSupplierInfoFromAccount?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        }
    },
};

export default supplierModule;