import Vue from "vue";
import VueRouter from "vue-router";
import TokenService from "@/services/token.service";

import { home } from './home';
import { account } from './account';
import { dashboard } from './dashboard';
import { kalender } from './kalender';
import { admin } from './admin';
import { galerijenreviews } from './galerijenreviews';
import { overons } from './overons';
import { misc } from './misc';
import { tripdaymanager } from './tripdaymanager';


Vue.use(VueRouter);

const routes = [
     // the default route, when none of the above matches:
     { path: "*", component: () => import("../views/misc/404Page.vue") }
];

routes.push(...home);
routes.push(...account);
routes.push(...dashboard);
routes.push(...kalender);
routes.push(...admin);
routes.push(...galerijenreviews);
routes.push(...overons);
routes.push(...misc);
routes.push(...tripdaymanager);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => 
{
    let view = document.querySelector('.content-wrapper');
    if (view)
    {
        view.scrollIntoView({behavior: 'auto', block: 'start', inline: 'start'});
    }

    const loggedIn = localStorage.getItem('user');
    let currentRoute = routes.find(x => x.name == to.name);
    let requiresAuth = currentRoute && currentRoute.requiresAuth;
    let requiresPrimaryUser = currentRoute && currentRoute.requiresPrimaryUser;
    let requiresRoleCheck = currentRoute && currentRoute.requiresRoleCheck;
    let isAdmin = false;

    if(loggedIn)
    {
        const userId = JSON.parse(loggedIn)["userId"];

        if (requiresRoleCheck || requiresPrimaryUser)
        {
            router.app.$store.dispatch('accountModule/isPrimaryUser', userId)
            .then(data => {
                isAdmin = data && TokenService.isAdmin();
                router.app.$store.commit('authorizedModule/setAdmin', isAdmin);

                if (requiresPrimaryUser && isAdmin) {
                    next();
                }
            })
            .finally(() => {

                if (requiresPrimaryUser && !isAdmin) {
                    // role not authorised so redirect to 404 page
                    return next('/404');
                }
            })
        }
        // check if userId is still valid
        router.app.$store.dispatch('accountModule/isAccountOwnerValid', userId)
        .then(data => {
            router.app.$store.state.account.status.loggedIn = data;
            if(data)
            {
                router.app.$store.commit('authorizedModule/setUserId', userId);
            }
            else
            {
                router.app.$store.commit('authorizedModule/setUserId', '');
                TokenService.removeUser();
                return next('/mijn-roadpursuit/login');
            }
        })
    }
    else
    {
        router.app.$store.dispatch('reset');
    }

    // redirect to login page if not logged in and trying to access a restricted page
    if (requiresAuth && !loggedIn) {
        return next('/mijn-roadpursuit/login');
    }

    // check if route is restricted by role
    if(requiresPrimaryUser && !loggedIn)
    {
        return next('/404');
    }

    if(!requiresPrimaryUser)
    {
        next();
    }
})


export default router;