export const dashboard = [
    {
        path: "/dashboard",
        name: "Mijn Roadpursuit",
        isMenu: true,
        requiresAuth: true,
        requiresRoleCheck: true,
        component: () => 
            import("@/views/dashboard/Dashboard.vue"),
    },
    {
        path: "/dashboard/account",
        name: "Account",
        requiresAuth: true,
        component: () => import("@/views/dashboard/Account.vue"),
    },
    {
        path: "/dashboard/account/verwijderen",
        name: "Account verwijderen",
        requiresAuth: true,
        component: () => import("@/views/dashboard/AccountVerwijderen.vue"),
    },
    {
        path: `/dashboard/aankomend/:trip`, // /{trip-name}-{day-month-year}/
        name: `Aankomende Roadtrip`,
        requiresAuth: true,
        component: () =>
            import("@/views/dashboard/AankomendeRoadtrip.vue"),
    },
    {
        path: `/dashboard/aankomend/:trip/wijzigen`, // /{trip-name}-{day-month-year}/wijzigen
        name: `Aankomende Roadtrip Wijzigen`,
        requiresAuth: true,
        component: () =>
            import("@/views/dashboard/AankomendeRoadtripWijzigen.vue"),
    },
    {
        path: `/dashboard/afgelopen`,
        name: `Afgelopen Roadtrips`,
        requiresAuth: true,
        component: () =>
            import("@/views/dashboard/AfgelopenRoadtrips.vue"),
    },
    {
        path: `/dashboard/afgelopen/:trip`, // /{trip-name}-{day-month-year}/
        name: `Afgelopen Roadtrip`,
        requiresAuth: true,
        component: () =>
            import("@/views/dashboard/AfgelopenRoadtrip.vue"),
    },
    {
        path: `/dashboard/voertuigen/:carId`, 
        name: `Voertuig`,
        requiresAuth: true,
        component: () =>
            import("@/views/dashboard/Voertuig.vue"),
    },
];