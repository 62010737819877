import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

const dialogModule = {
    namespaced: true,
    state:
    {
        isDialogVisible: false,
        message: "",

        isActionDialogVisible: false,
        actionMessage: "",

        isPictureDialogVisible: false,
        imgSrc: "",
    },
    mutations:
    {
        showDialog(state, message)
        {
            state.isDialogVisible = true;
            state.message = message;
        },
        hideDialog(state)
        {
            state.isDialogVisible = false;
        },

        showActionDialog(state, message)
        {
            state.isActionDialogVisible = true;
            state.actionMessage = message;
        },
        hideActionDialog(state)
        {
            state.isActionDialogVisible = false;
        },

        showPictureDialog(state, src)
        {
            state.isPictureDialogVisible = true;
            state.imgSrc = src;
        },
        hidePictureDialog(state)
        {
            state.isPictureDialogVisible = false;
        },
        setPictureDialogVisibility(state, val)
        {
            state.isPictureDialogVisible = val;
        }
    },
    actions:
    {

    },
};

export default dialogModule;