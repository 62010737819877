import axiosInstance from "./api";
import TokenService from "./token.service";
import axios from "axios";
import router from "@/router";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            Promise.reject(error)
        }
    );
    const interceptor = axiosInstance.interceptors.response.use(
        response => response,
        error => {
            const originalConfig = error.config;
            // Reject promise if usual error
            if (error.response && error.response.status !== 401) {
                return Promise.reject(error);
            }

            // When response code is 401, try to refresh the token.
            // Eject the interceptor so it doesn't loop in case
            // token refresh causes the 401 response
            axios.interceptors.response.eject(interceptor);

            if(!originalConfig._retry)
            {
                originalConfig._retry = true;
                return axios.post('/Authentication/RefreshToken', {
                    Token: TokenService.getLocalRefreshToken()
                }).then(response => {
                    store.dispatch('account/refreshToken', response.data.token);
                    TokenService.updateLocalAccessToken(response.data.token);
                    originalConfig.headers['Authorization'] = 'Bearer ' + response.data.token;
                    return axios(originalConfig);
                }).catch(error => {
                    if((error.response && error.response.status !== 401)  && TokenService.getUser() != null)
                    {
                        store.dispatch('accountModule/revokeToken', { token: TokenService.getLocalRefreshToken()});
                        store.commit('authorizedModule/setUserId', '');
                        TokenService.removeUser();
                        router.push('/mijn-roadpursuit/login');
                    }
                    return Promise.reject(error);
                }).finally(setup);
            }

           
    });
};
export default setup;