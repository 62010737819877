<template>
    <div class="message-dialog">
        <v-row class="no-gutters">
            <v-col cols="auto">
                <v-dialog persistent color="white"
                    max-width="600"
                    v-model="dialogVisible">
                    <template>
                        <v-card>
                            <v-card-text>
                                <div class="subtitle-2 px-8 pt-10" v-html="dialogMessage"></div>
                            </v-card-text>
                            <v-card-actions class="pb-5">
                                <v-spacer></v-spacer>
                                <v-btn @click.stop="ok" @keyup.enter.stop="yes">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MessageDialog',

    data: () => ({
       
    }),
    computed: {
        ...mapState({
            dialogVisible: state => state.dialog.isDialogVisible,
            dialogMessage: state => state.dialog.message
        })
    },
    methods: {
        ok() {

            this.$store.commit('dialog/hideDialog');
        }
    }
  }
</script>
