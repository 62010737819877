/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const roadtripModule = {
    namespaced: true,
    state:
    {
        isLoading: false,
    },
    getters:
    {
        
    },
    mutations:
    {
        setLoading(state, isLoading)
        {
            state.isLoading = isLoading;
        },
    },
    actions:
    {
        getRoadtripStatistics({}, roadtripId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/admin/GetRoadtripStatistics?roadtripId=${roadtripId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getRoadtripStatisticsByTimeFrame({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/admin/dashboard/GetRoadtripStatisticsByTimeFrame`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        addRoadtrip({commit, dispatch}, request)
        {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                api.post(`/Roadtrip/AddRoadtrip`, request)
                .then(response => {

                    // images
                    let imageRequest = new FormData();
                    imageRequest.append('ProductId', response.data.productId);
                    request.productPhotos.forEach(photo => {
                        if (photo)
                        {
                            imageRequest.append(`ProductPhotos`, photo);
                        }
                    });

                    // GPX
                    if (request.gpx_file != null && request.gpx_file != "")
                    {
                        let gpxRequest = new FormData();
                        gpxRequest.append('RoadtripId', response.data.roadtripId);
                        gpxRequest.append('File', request.gpx_file);

                        Promise.all([
                            dispatch('addRoadtripPhotos', imageRequest),
                            dispatch('addGPXToRoadtrip', gpxRequest),
                        ]).then(() => {
                            resolve(response.data);
                        })
                        .catch(error => reject(error.response))
                    }
                    else
                    {
                        Promise.all([
                            dispatch('addRoadtripPhotos', imageRequest),
                        ]).then(() => {
                            resolve(response.data);
                        })
                        .catch(error => reject(error.response))
                    }
                })
                .catch(error => reject(error.response))
            })
        },
        addRoadtripPhotos({commit}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Product/AddRoadtripProductPhotos`, request,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    commit('dialog/showDialog', 'Something went wrong with saving photos.', {root: true})
                    reject(error.response)
                })
            })
        },
        addGPXToRoadtrip({commit}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/AddGPXToRoadtrip`, request,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    commit('dialog/showDialog', 'Something went wrong with saving gpx file.', {root: true})
                    reject(error.response)
                })
            })
        },
        updateRoadtrip({commit, dispatch}, request)
        {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                api.post(`/Product/UpdateProduct`, request)
                .then(response => {

                    // images
                    let imageRequest = new FormData();
                    imageRequest.append('ProductId', request.productId);
                    request.newPhotos.forEach(photo => {
                        if (photo)
                        {
                            imageRequest.append(`ProductPhotos`, photo);
                        }
                    });

                    // GPX
                    let gpxRequest = new FormData();
                    gpxRequest.append('RoadtripId', request.roadtripId);
                    gpxRequest.append('File', request.gpx_file);
                    gpxRequest.append('Changes', request.gpx_file_changes);

                    if(request.gpx_file_changes != '' && request.gpx_file != null)
                    {
                        Promise.all([
                            dispatch('updateGPXFile', gpxRequest),
                            dispatch('addRoadtripPhotos', imageRequest)
                        ]).then(() => {
                            resolve(response.data);
                        })
                        .catch(error => reject(error.response))
                    }
                    else
                    {
                        Promise.all([
                            dispatch('addRoadtripPhotos', imageRequest)
                        ]).then(() => {
                            resolve(response.data);
                        })
                        .catch(error => reject(error.response))
                    }
                })
                .catch(error => reject(error.response))
            })
        },
        updateGPXFile({commit}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/UpdateGPXFile`, request,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    commit('dialog/showDialog', 'Something went wrong with updating gpx file.', {root: true})
                    reject(error.response)
                })
            })
        },
        // dashboard
        getUpcomingRoadtrips({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/GetUpcomingRoadtrips?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getUpcomingRoadtripInformation({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/GetUpcomingRoadtripInformation`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getPassedRoadtrips({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/GetPassedRoadtrips?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getPassedRoadtrip({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/GetPassedRoadtrip`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getRoadtripId({}, uniqueName)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/GetRoadtripId?uniqueName=${uniqueName}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getTripDayStatistics({}, roadtripId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/GetTripDayStatistics?roadtripId=${roadtripId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        scanQR({}, orderId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/ScanQR?orderId=${orderId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getUnauthorizedScanQRInfo({}, orderId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Roadtrip/GetUnauthorizedScanQRInfo?orderId=${orderId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        updateCheckInStatus({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Roadtrip/UpdateCheckInStatus?orderId=${request.orderId}&checkIn=${request.isPresent}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
};

export default roadtripModule;