/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const reviewModule = {
    namespaced: true,
    state:
    {
        
    },
    mutations:
    {
    },
    actions:
    {
        canPlaceReview({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/RoadtripReview/CanPlaceReview`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        placeReview({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/RoadtripReview/PlaceReview`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        updateReview({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/RoadtripReview/UpdateReview`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getAllReviewsFromRoadtrip({}, roadtripId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/RoadtripReview/GetAllReviewsFromRoadtrip?roadtripId=${roadtripId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        removeReview({}, request)
        {
            return new Promise((resolve, reject) => {
                api.delete(`/RoadtripReview/RemoveReview?reviewId=${request.reviewId}&accountId=${request.accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        }
    },
};

export default reviewModule;