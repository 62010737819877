import axios from "axios";
// import axios from "axios";

const instance = axios.create({
  headers: {
    baseURL : process.env.VUE_APP_BASE_URL,
    "Content-Type": "application/json",
  },
});

instance.defaults.baseURL = process.env.VUE_APP_BASE_URL;
export default instance;