/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const newsletterModule = {
    namespaced: true,
    state:
    {
    },
    getters:
    {
        
    },
    mutations:
    {
       
    },
    actions:
    {
        subscribeNonExistingAccountToNewsletter({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Newsletter/SubscribeNonExistingAccountToNewsletter`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        unsubscribeExistingAccountFromNewsletter({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Newsletter/UnsubscribeExistingAccountFromNewsletter?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        subscribeExistingAccountToNewsletter({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Newsletter/SubscribeExistingAccountToNewsletter?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        accountIsSubscribed({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Newsletter/AccountIsSubscribed?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
};

export default newsletterModule;