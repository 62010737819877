/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';
import axios from "axios";

Vue.use(Vuex);

const accountModule = {
    namespaced: true,
    state:
    {
        personInfo: {
            foodRestrictions: []
        },
        accountAddress: {},
    },
    getters:
    {

    },
    mutations:
    {
        setAccountPersonInfo(state, info)
        {
            state.personInfo = info;
        },
        setAccountAddress(state, address)
        {
            state.accountAddress = address;
        },
        addUpdateClickCounter(state)
        {
            state.updateAccountClickCounter++;
        }
    },
    actions:
    {
        registerAccount({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`Account/RegisterToRoadpursuit`, request)
                .then(response => {
                    resolve(!response.data);
                })
                .catch(error => reject(error))

            })
        },
        isEmailAvailable({}, email)
        {
            return new Promise((resolve, reject) => {
                api.get(`Account/TryAccountExists?emailAddress=${email}`)
                .then(response => {
                    resolve(!response.data);
                })
                .catch(error => reject(error))

            })
        },
        getFirstNameFromActivationString({}, activationString)
        {
            return new Promise((resolve, reject) => {
                api.get(`Person/GetFirstNameFromActivationString?activationString=${activationString}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))

            })
        },
        getEmailAddressFromAccountOwner({}, accountId)
        {
            if(!accountId) return;

            return new Promise((resolve, reject) => {
                api.post(`Person/GetEmailAddressFromAccountOwner?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))

            })
        },
        activateAccount({}, activationString)
        {
            return new Promise((resolve, reject) => {
                api.post(`Account/ActivateAccount?activationString=${activationString}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        requestAccountDeletionLink({rootState})
        {
            return new Promise((resolve, reject) => {
                api.get(`Account/RequestAccountDeletionLink?accountId=${rootState.authorizedModule.userId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))

            })
        },
        blockAccount({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/admin/BlockAccount?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        unblockAccount({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/admin/UnblockAccount?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        removeAccount({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/admin/RemoveAccount?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getAccountOwnerBasePersoninfo({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Person/GetAccountOwnerBasePersoninfo?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getAddressFromAccountowner({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`Address/GetAddressFromAccountowner?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getCoRegisteredPersonsFromAccount({}, accountId)
        {
            return new Promise((resolve, reject) => {
                api.get(`Person/GetCoregisteredPersonsFromAccount?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getAccountsByTimeFrame({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/admin/GetAccountsByTimeFrame`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getAccountStatisticsByTimeFrame({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/admin/dashboard/GetAccountStatisticsByTimeFrame`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        updatePersonInfo({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`Person/UpdatePersonInfo`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        updateAddressFromAccountowner({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`Address/UpdateAddressFromAccountowner`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        deletePerson({}, personId)
        {
            return new Promise((resolve, reject) => {
                api.delete(`/Person/SoftDeletePerson?personId=${personId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        addCoregisteredPersons({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`Person/AddCoregisteredPersons`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        confirmAccountDeletionRequest({}, deletionString)
        {
            return new Promise((resolve, reject) => {
                api.delete(`/Account/ConfirmAccountDeletionRequest?deletionString=${deletionString}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getPasswordChangeLink({}, email)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Account/GetPasswordChangeLink?emailAddress=${email}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        changePassword({}, request) // from email link
        {
            return new Promise((resolve, reject) => {
                api.post(`Account/ChangePassword`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        changePasswordDashBoard({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`Account/ChangePasswordDashBoard`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        reset({commit})
        {
            commit('setAccountPersonInfo', {
                foodRestrictions: []
            });
            commit('setAccountAddress', {});
        },
        isAccountOwnerValid({}, userId)
        {
            return new Promise((resolve, reject) => {
                axios.get(`/authentication/IsAccountOwnerValid?userId=${userId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        isPrimaryUser({}, userId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/authentication/IsPrimaryUser?userId=${userId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        revokeToken({}, request)
        {
            return new Promise((resolve, reject) => {
                axios.post('/authentication/RevokeToken', request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        
    },
};

export default accountModule;