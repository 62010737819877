export const misc = [
    {
        path: "/404",
        name: "404 - Not Found",
        component: () => import("@/views/misc/404Page.vue"),
    },
    {
        path: "/401",
        name: "401 - Unauthorized",
        component: () => import("@/views/misc/401Unauthorized.vue"),
    },
    {
        path: "/unsubscribe/:unsubscribeString",
        name: "Unsubscribe",
        component: () => import("@/views/misc/Unsubscribe.vue"),
    },
    {
        path: "/verwijderen/:deletionString",
        name: "ConfirmAccountDeletion",
        component: () => import("@/views/misc/ConfirmAccountDeletion.vue"),
    },
    {
        path: "/wachtwoord-wijzigen/:passwordChangeString",
        name: "Wachtwoord Wijzigen Link",
        component: () => import("@/views/misc/WachtwoordWijzigen.vue"),
    },
    {
        path: "/redirect/:accountId/:url",
        name: "Redirect",
        component: () => import("@/views/misc/Redirect.vue"),
    },
    {
        path: "/aanmelding/:orderId",
        name: "RoadtripAanmelding",
        component: () => import("@/views/misc/RoadtripAanmelding.vue"),
    },
];