<template>
  <div class="font-weight-thin nav-wrapper">
    <v-app-bar fixed dark :height="68">
        <v-toolbar>
            <img @click="onRouteClick('/')" transition="fade-transition" class="main-logo logo" :src="logoSrc" alt="RP" :class="{ 'hamburger--is-open slow-closing' :overlay, 'slow-opening' :overlay==false }">
        <v-spacer />
        <v-toolbar-title v-show="!$vuetify.breakpoint.xs">
        </v-toolbar-title>
        <v-spacer />
        <button class="hamburger app" @click="toggleOverlay()" :class="{ 'hamburger--is-open slow-closing' :overlay, 'slow-opening' :overlay==false }"></button>
        
        </v-toolbar>
    </v-app-bar>

    <v-row class="mobile-menu-bar" :class="{ 'slow-opening' :overlay, 'slow-closing' :overlay==false }" >
        <img class="logo" :src="logoSrc" alt="RP">
        <button class="hamburger app" @click="toggleOverlay()" :class="{ 'hamburger--is-open' :overlay }"></button>

        <SocialMediaLinks />
    </v-row>
    <v-overlay :z-index="7" :class="{ 'slow-opening' :overlay, 'slow-closing' :overlay==false }" class="menu-overlay"
        @click="overlay = false">
        <div>
            <div id="staggered-list" class="text-center">
                <transition-group
                    name="staggered-fade"
                    tag="ul"
                    v-bind:css="false"
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter">
                        <div v-for="(item, index) in computedList" @click="onRouteClick(item.path)"
                        v-bind:key="index" 
                        v-bind:data-index="index" class="mobile-menu pointer"
                        :class="{ 'active' :$router.currentRoute.path == item.path }"
                        >{{ getFilteredName(item.name) }}</div>
                </transition-group>
            </div>

        </div>
    </v-overlay>
  </div>
</template>

<script>
import Velocity from 'velocity-animate'
import RouteMixin from "@/mixins/route.mixin";
import SocialMediaLinks from "@/components/common/SocialMediaLinks";

export default {
    name: 'Menu',
    props: {
        routes: Array,
    },
    components: {
        SocialMediaLinks
    },
    data: () => ({
        logoSrc:  require('@/assets/icons/roadpursuit-grey-logo.svg'),
        hamburgerOpen: false,
        overlay: false,
        query: '*',
    }),
    mixins: [RouteMixin],
    computed: {
        computedList: function () {
            var vm = this
            return this.routes.filter(function (item) {
                return item.name.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1
            })
        }
    },
    watch: {
        overlay: {
            immediate: true,
            handler() {
                if(this.overlay)
                {
                    document.documentElement.classList.add('noscroll');
                }
                else
                {
                    document.documentElement.classList.remove('noscroll');
                }
            }
        },
    },
    methods: {
        getFilteredName(name)
        {
            // workaround way to solve caching issue of router because kalender could not be renamed
            return name.startsWith("Roadtrips") ? "Roadpursuit kalender" : name;
        },
        onRouteClick(item)
        {
            let currentRoute = this.$router.currentRoute.path;
            if(currentRoute != item)
            {
                this.$router.push({ path: item });
            }

            if (this.overlay)
            {
                this.toggleOverlay();
            }

            var el = document.querySelector(".content-wrapper");
            el.scrollTo(0,0);
        },
        toggleOverlay()
        {
            this.overlay = !this.overlay;
            this.query = this.overlay ? "" : "*";
           
        },
        beforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
            el.style.right = -150
        },
        enter: function (el, done) {
            var delay = el.dataset.index * 50
            setTimeout(function () {
                Velocity(
                el,
                { opacity: 1, height: 50, right: 0 },
                { complete: done }
                )
            }, delay)
        },
    }
  }
</script>

<style>
.v-app-bar--fixed
{
    box-shadow: none;
}

.nav-wrapper 
{
    height: 65px;
}

.v-toolbar__content
{
    padding: 0px !important;
}

.v-toolbar__title a 
{
    font-size: 16px;
    text-decoration: none;
    padding: 0px 20px;
    color: white;
}

.v-toolbar__title a:active,
.v-toolbar__title a:hover
{
     color: #2d78bf;
}

.v-app-bar.v-app-bar--fixed
{
    z-index: 7 !important;
}

.hamburger {
    position: relative;
    display: flex;
    height: 80px;
    width: 80px;
    border: 0;
    cursor: pointer;
    pointer-events: all;
    outline: none;
}

.hamburger::before,
.hamburger::after {
    content: "";
    position: absolute;
    top: 34px;
    left: 25px;
    background: white;
    width: 25px;
    height: 2px;
    transition: transform 100ms ease-out;
}

.hamburger:after {
    top: auto;
    bottom: 33px;
}

.hamburger--is-open:before {
    transform: translateY(6px) rotate(135deg);
} 

.hamburger--is-open:after {
    transform: translateY(-4px) rotate(45deg);
}

.main-overlay.v-overlay--active
{
    background: #363630ed;
}

.main-overlay.v-overlay--active
{
    height: 100vh;
    width: 100vw;
}

.menu-overlay,
.menu-overlay .v-overlay__scrim
{
    background-color: rgb(0 0 0 / 65%) !important;
    backdrop-filter: blur(10px);
}

.slow-opening
{
    visibility: visible;
    opacity:1;
    transition:visibility 0.1s linear,opacity 0.1s linear;
}

.slow-closing
{
    visibility: hidden;
    opacity:0;
    transition:visibility 0.1s linear,opacity 0.1s linear;
}

.mobile-menu-bar
{
    position: fixed;
    width: 100vw;
    z-index: 15;
    margin: 0px !important;
}

.mobile-menu-bar .hamburger
{
    right: 0px;
    position: absolute;
    top: -6px;
    right: -5px;
}

.mobile-menu-bar .logo
{
    position: absolute;
    top: 21px;
    cursor: default;
}

.mobile-menu-bar .logo-fb,
.mobile-menu-bar .logo-insta
{
    position: fixed;
    bottom: 35px;
    margin: 0px 5px;
}

.mobile-menu-bar .logo-fb
{
    right: 50%;
}

.mobile-menu-bar .logo-insta
{
    left: 50%;
}

.mobile-menu.active,
.mobile-menu:hover {
    color: #2d78bf;
}

.md img.main-logo,
.lg img.main-logo,
.xl img.main-logo 
{
    top: 19px !important;
}

img.logo {
    left: 15px;
    top: 15px;
    position: fixed;
    padding: 2px;
    -o-object-fit: contain;
    object-fit: contain;
    width: 33px;
    height: 25px;
    cursor: pointer;
}

#staggered-list
{
    text-align: center;
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
}
</style>