export const galerijenreviews = [
    {
        path: "/galerij",
        name: "Galerij en reviews",
        isMenu: true,
        component: () =>
            import("@/views/galerijenreviews/GalerijEnReviews.vue"),
    },
    {
        path: `/galerij/:make`,
        name: `Merk galerij`,
        component: () =>
            import("@/views/galerijenreviews/MerkGalerij.vue"),
    },
    {
        path: `/galerij/:make/:trip`,
        name: `Trip galerij`,
        component: () =>
            import("@/views/galerijenreviews/TripGalerij.vue"),
    },
];