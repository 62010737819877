import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const menuHelper = {
    namespaced: true,
    state:
    {
        foodRestrictions: [],
        fuelTypes: [],
        mileageTypes: [],
        billingTypes: [],
        vehicleMakes: [],
        vehicleTypes: [],
        supplierTypes: [],
        suppliers: [],
        VATRates: [],
        itineraryTypes: [],
        roadtripLocationTypes: [],
        roadtripLabels: [],
        productCategories: [],
        productStatuses: [],
        countries: [],
        accountOrigins: [],
    },
    mutations:
    {
        setFoodRestrictions(state, data)
        {
            state.foodRestrictions = data;
        },
        setFuelTypes(state, data)
        {
            state.fuelTypes = data;
        },
        setMileageTypes(state, data)
        {
            state.mileageTypes = data;
        },
        setBillingTypes(state, data)
        {
            state.billingTypes = data;
        },
        setVehicleMakes(state, data)
        {
            state.vehicleMakes = data;
        },
        setVehicleTypes(state, data)
        {
            state.vehicleTypes = data;
        },
        setSupplierTypes(state, data)
        {
            state.supplierTypes = data;
        },
        setSuppliers(state, data)
        {
            state.suppliers = data;
        },
        setVATRates(state, data)
        {
            state.VATRates = data;
        },
        setItineraryTypes(state, data)
        {
            state.itineraryTypes = data;
        },
        setRoadtripLocationTypes(state, data)
        {
            state.roadtripLocationTypes = data;
        },
        setRoadtripLabels(state, data)
        {
            state.roadtripLabels = data;
        },
        setProductCategories(state, data)
        {
            state.productCategories = data;
        },
        setProductStatuses(state, data)
        {
            state.productStatuses = data;
        },
        setCountries(state, data)
        {
            state.countries = data;
        },
        setAccountOrigins(state, data)
        {
            state.accountOrigins = data;
        },
    },
    actions:
    {
        getVehicleOptions({dispatch}) {
            dispatch('getVehicleTypes');
            dispatch('getVehicleMakes');
            dispatch('getFuelTypes');
        },
        getFoodRestrictions({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetFoodRestrictions`)
                .then(response => {
                    commit('setFoodRestrictions', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getFuelTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetFuelTypes`)
                .then(response => {
                    commit('setFuelTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getMileageTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetMileageTypes`)
                .then(response => {
                    commit('setMileageTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getBillingTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetBillingTypes`)
                .then(response => {
                    commit('setBillingTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getVehicleMakes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetVehicleMakes`)
                .then(response => {
                    commit('setVehicleMakes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getVehicleTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetVehicleTypes`)
                .then(response => {
                    commit('setVehicleTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getSupplierTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetSupplierTypes`)
                .then(response => {
                    commit('setSupplierTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getVATRates({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetVATRates`)
                .then(response => {
                    commit('setVATRates', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getItineraryTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetItineraryTypes`)
                .then(response => {
                    commit('setItineraryTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getRoadtripLocationTypes({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetRoadtripLocationTypes`)
                .then(response => {
                    commit('setRoadtripLocationTypes', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getRoadtripLabels({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetRoadtripLabels`)
                .then(response => {
                    commit('setRoadtripLabels', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getProductCategories({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetProductCategories`)
                .then(response => {
                    commit('setProductCategories', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getProductStatuses({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetProductStatuses`)
                .then(response => {
                    commit('setProductStatuses', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getCountries({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetCountries`)
                .then(response => {
                    commit('setCountries', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getAccountOrigins({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetAccountOrigins`)
                .then(response => {
                    commit('setAccountOrigins', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },

        // admin
        getSuppliers({commit})
        {
            return new Promise((resolve, reject) => {
                api.get(`MenuHelper/GetSuppliers`)
                .then(response => {
                    commit('setSuppliers', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
    },
};

export default menuHelper;