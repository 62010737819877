export default {
    data: function ()
    {
        return {
            decimalSeparator: ",",
            account_age_limit: 16
        }
    },
    methods: 
    {
        getToday() {
            // Format: YYYY-MM-DD
            return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        getCurrentYear()
        {
            return new Date().getFullYear();
        },
        getCurrentQuarter()
        {
            return Math.floor(new Date().getMonth() / 3 + 1);
        },
        getCurrentMonth()
        {
            const d = new Date();
            let month = d.getMonth();
            //  returns the month (from 0 to 11)
            return month + 1;
        },
        formatDate (date) {
            if (!date) return null
            // Format: DD-MM-YYYY from input YYYY-MM-DD
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        formatDateWithoutTime(date)
        {
            if (!date) return null;
           
            // return format DD-MM-YYYY
            date = this.getDateOnly(date);
            return this.formatDate(date);
        },
        getDateOnly(date)
        {
            // remove time information from input YYYY-MM-DDTHH:mm:ss
            if (!date) return null;
            // returns YYYY-MM-DD
            return date.substr(0, 10);
        },
        getYearOnly(date)
        {
            if (!date) return null;
            // returns YYYY
            return date.substr(0, 4);
        },
        getMonthOnly(date)
        {
            if (!date) return null;
            // returns MM from YYYY-MM
            return date.substr(5, 2);
        },
        getTimeOnly(date)
        {
            // get time information from input YYYY-MM-DDTHH:mm:ss
            if (!date) return null;
            // returns HH:mm
            return date.substr(11, 5);
        },
        minBirthdate() {
            return '1900-01-01';
        },
        maxBirthdate(ageLimit) {
            // Format: YYYY-MM-DD
            // minimum age should be 16 years old.
            const [year, month, day] = this.getToday().split('-');
            return `${year - ageLimit}-${month}-${day}`;
        },
       
        parseDate (date) {
            if (!date) return null
    
            const [day, month, year] = date.split('-') // return YYYY-MM-DD
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
          },
        formatDayMonth(date)
        {
            if(date == undefined) return date;
            
            // e.g. 13 september
            const d = new Date(date);
            const [,, day] = date.split('-')
                return `${day} ${d.toLocaleString('nl', { month: 'long' })}`;
        },
        formatDayMonthYear(date)
        {
            if(date == undefined) return date;

            // e.g. 14 juli, 2018
            const d = new Date(date);
            const [year,, day] = date.split('-')
                return `${day} ${d.toLocaleString('nl', { month: 'long' })}, ${year}`;
        },
        addDays(date, days)
        {
            var newDate = new Date(new Date(date).setDate(new Date(date).getDate() + days));
            return newDate.toISOString().substr(0, 10);
        },

        // currency
        getCurrency(amount)
        {
            if(amount == undefined) return '';
            
            return (amount).toLocaleString('nl-NL', {
                style: 'currency',
                currency: 'EUR',
              })
        },
        getLocalNumericDisplay(input)
        {
            const defaultEmpty = input == 0 ? "0" : "";
            return input ? input.toString().replace('.', this.decimalSeparator) : defaultEmpty;
        },
        localParseFloat(input)
        {
            let num = input.replace(this.decimalSeparator, ".");

            // Now it can be parsed
            return this.convertToNullableFloat(num);
        },
        convertToNullableFloat(num)
        {
            let number = parseFloat(num);
            if(isNaN(number))
            {
                return null; // prevent parsing errors to nullable decimal.
            }

            return number;
        },

        getPersonInitials(firstname, lastname)
        {
            const names = lastname.split(' ');
            return `${firstname.charAt(0)}${names[names.length - 1].charAt(0)}`;
        },
    }
}