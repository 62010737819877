import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const authorizedModule = {
    namespaced: true,
    state:
    {
        samples: [],
        userId: '',
        isAdmin: false
    },
    getters:
    {

    },
    mutations:
    {   
        setSamples(state, data)
        {
            state.samples = data;
        },
        setUserId(state, id)
        {
            state.userId = id;
        },
        setAdmin(state, isAdmin)
        {
            state.isAdmin = isAdmin;
        }
    },
    actions:
    {
    },
};


export default authorizedModule;