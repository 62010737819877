/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const vehicleModule = {
    namespaced: true,
    state:
    {
        
    },
    mutations:
    {
    },
    actions:
    {
        getVehiclesByAccountId({}, accountId)
        {
            if(!accountId) return;

            return new Promise((resolve, reject) => {
                api.get(`/Vehicle/GetVehiclesByAccountId?accountId=${accountId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getVehicle({}, vehicleId)
        {
            if(!vehicleId) return;

            return new Promise((resolve, reject) => {
                api.get(`Vehicle/GetVehicle?vehicleId=${vehicleId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getLicenseplateInformation({}, licensePlate)
        {
            if(!licensePlate) return;

            return new Promise((resolve, reject) => {
                api.get(`/Vehicle/GetLicenseplateInformation?licensePlate=${licensePlate}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getVehicleStatisticsByTimeFrame({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Vehicle/admin/dashboard/GetVehicleStatisticsByTimeFrame`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        addVehicle({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Vehicle/AddVehicle`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        updateVehicle({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Vehicle/UpdateVehicle`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })   
        },
        deleteVehicle({}, vehicleId)
        {
            if(!vehicleId) return;
            
            return new Promise((resolve, reject) => {
                api.delete(`Vehicle/SoftDeleteVehicle?vehicleId=${vehicleId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })   
        },
    },
};

export default vehicleModule;