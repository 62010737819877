/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';

Vue.use(Vuex);

const storeModule = {
    namespaced: true,
    state:
    {
        
    },
    getters:
    {

    },
    mutations:
    {   
    },
    actions:
    {
        placeOrderWithExistingAccount({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Store/PlaceOrderWithExistingAccount`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        placeOrderWithNewAccount({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Store/PlaceOrderWithNewAccount`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        getOrdersInformation({}, request)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Store/GetOrdersInformation?accountId=${request.accountId}&productId=${request.productId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        },
        checkPaymentStatusByOrderId({}, orderId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Store/CheckPaymentStatusByOrderId?orderId=${orderId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        validateVoucher({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Voucher/ValidateVoucher`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        }
    },
};


export default storeModule;