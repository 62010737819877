import Vue from 'vue';
import Vuetify from 'vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

// import CSS styles
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'


// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify({
    icons: 
    {
        iconfont: 'mdi',
    },
    theme: { 
        themes: {
            dark: {
                primary: '#0071E3',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
          },
        
        dark: true 
    },
    options: {
        customProperties: true
    }
});

// use Vuetify's plugin
Vue.use(Vuetify)
// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

export default vuetify;
