/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import api from '@/services/api';
import axios from '@/services/api';

Vue.use(Vuex);

const galleryModule = {
    namespaced: true,
    state:
    {
    },
    getters:
    {
        
    },
    mutations:
    {
       
    },
    actions:
    {
        likePhoto({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Gallery/LikePhoto?`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getRoadtripGalleryLoggedOut({}, roadtripId)
        {
            return new Promise((resolve, reject) => {
                axios.get(`/Gallery/GetRoadtripGalleryLoggedOut?roadtripId=${roadtripId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        GetRoadtripGalleryLoggedIn({}, request)
        {
            return new Promise((resolve, reject) => {
                api.post(`/Gallery/GetRoadtripGalleryLoggedIn?`, request)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getGalleries({})
        {
            return new Promise((resolve, reject) => {
                api.get(`/Gallery/GetGalleries`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getGalleriesFromMake({}, makeId)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Gallery/GetGalleriesFromMake?roadtripLabel=${makeId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        }
    },
};

export default galleryModule;