<template>
    <div id="app" :class="this.$vuetify.breakpoint.name">
        <v-main app class="main-content">
            <v-layout>
                <template>
                    <v-col class="text-center d-flex justify-center content-wrapper" no-gutters>
                        <Menu :routes="$router.options.routes.filter(x => x.isMenu)" />
                        <v-app>
                            <router-view />
                        </v-app>
                        <Footer />
                    </v-col>
                </template>
            </v-layout>

            <MessageDialog />
        </v-main>
    </div>

</template>

<script>

import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import MessageDialog from "@/components/dialogs/MessageDialog";

export default {
    name: 'Home',
    metaInfo: {
        titleTemplate: "Roadpursuit %s"
    },
    components: {
        Menu,
        Footer,
        MessageDialog
    },
   
    data () {
        return {

        }
    }
}
</script>


<style>

#app
{
    margin: auto;
}
body.modal-open{
    overflow:hidden;
}
.main-content
{
    max-height: 100vh;
}

</style>