import Vue from "vue";
import Vuex from "vuex";

import authorizedModule from './modules/authorized.module'
import roadtripModule from './modules/roadtrips.module';
import productModule from './modules/product.module';
import accountModule from "./modules/account.module";
import vehicleModule from "./modules/vehicle.module";
import supplierModule from "./modules/supplier.module";
import expenseModule from "./modules/expense.module";
import galleryModule from "./modules/gallery.module";
import reviewModule from "./modules/roadtrip-review.module";
import newsletterModule from "./modules/newsletter.module";
import storeModule from "./modules/store.module";
import menuHelper from "./modules/menu-helper.module";
import dialogModule from "./modules/dialog.module";
import { account } from './const/account';
import { alert } from './const/alert';
import api from '@/services/api';

Vue.use(Vuex);

export default new Vuex.Store({
    state:
    {
        isPilot: true, // Pilot version does not yet support facturen and single vehicle information.
        selectedYear: new Date().getFullYear(),
        showFooter: true
    },
    getters:
    {
    },
    mutations:
    {
        setFooterVisibility(state, value)
        {
            state.showFooter = value;
        }
    },
    actions:
    {
        getAccountInformation({commit, dispatch}, userId)
        {
            return new Promise((resolve, reject) => {
                dispatch('accountModule/getAccountOwnerBasePersoninfo', userId)
                .then((data) => {
                    commit('accountModule/setAccountPersonInfo', data);

                    dispatch('accountModule/getAddressFromAccountowner', userId)
                    .then((data) => {
                        commit('accountModule/setAccountAddress', data);
                        resolve(data);
                    });
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        reset({dispatch})
        {
            dispatch('accountModule/reset');
        },
        // eslint-disable-next-line no-empty-pattern
        emailTrack({}, request)
        {
            return new Promise((resolve, reject) => {
                api.get(`/Email/Track?accountId=${request.accountId}&redirect=${request.url}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data))
            })
        }
    },
    modules:
    {
        roadtripModule: roadtripModule,
        productModule: productModule,
        authorizedModule: authorizedModule,
        accountModule: accountModule,
        vehicleModule: vehicleModule,
        supplierModule: supplierModule,
        expenseModule: expenseModule,
        galleryModule: galleryModule,
        reviewModule: reviewModule,
        newsletterModule: newsletterModule,
        storeModule: storeModule,
        menuHelper: menuHelper,
        dialog: dialogModule,
        alert,
        account
    }
});